import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Index } from './Pages/Index/Index'

const Rourtes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Index/>}/>
      </Routes>
    </BrowserRouter>
  )
}

export default Rourtes
