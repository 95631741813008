import React from 'react'
import { ReactComponent as Github } from 'Assets/Icons/Github.svg'
import { ReactComponent as IconLink } from 'Assets/Icons/Link.svg'

export const ProjectCard = ({ information }) => {

    return (
        <div className="project_card">
            <div className="header_project_card">
                <img className='project_image' src={information.image} alt="" />
                <div className="project_info">
                    <div className='project_links'>
                        <div className="repositorie">
                            <a href={information.repositorie} target='__blank'>
                                <Github className='icon_github' />
                            </a>
                        </div>
                        <a href={information.url} className="link_web" target='__blank'>
                            <IconLink className='icon_link' />
                            {/* <p className='url'>{information.url}</p> */}
                        </a>
                    </div>
                    <p className="creation_date">27 Jul 2022</p>
                </div>
            </div>
        </div>
    )
}
