import React from 'react'
import { Nav } from '../Nav/Nav'

export const Header = () => {   

    return (
        <header className='section_header'>
            <div className="center_header">
                <p className='logo'></p>
                <Nav/>
            </div>
        </header>
    )
}
