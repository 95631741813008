import React from 'react'
import { Banner } from '../../Components/Layout/Banner/Banner'
import { Header } from '../../Components/Layout/Header/Header'
import { Works } from '../../Components/Layout/Works/Works'

export const Index = () => {
  return (
    <>
      <Header />
      <main>
        <Banner />
        <Works />
      </main>
    </>
  )
}
