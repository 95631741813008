import axios from 'axios'
import { useEffect, useState } from 'react'

export const useFetch = (url) => {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(null)
    const [error, setError] = useState(null)

    useEffect(() =>{ 
        setLoading(true)
        try {
            axios.get(url)
            .then(response => { 
                setData(response.data)
                // console.log(response.data)
            })
            .finally(() => setLoading(false))
        } catch (error) {
            setError(error)
        }
    },[])

    return {
        data,
        loading,
        error
    }
}
