import { Link } from "react-router-dom";

export const Nav = () => {

    const handle_click_options = (navigate) => {
        const section = document.querySelector(`.${navigate}`)
        const { x, y, width} = section.getBoundingClientRect()
        console.log(x, y);
        console.log(section.getBoundingClientRect());
        window.scroll({
            top: y,
            left: 0,
            behavior: 'smooth'
        });

        console.log(width - y);
    }

    return (
        <nav className='nav_options'>
            <div className="option active">
                <p className="option_name">Home</p>
            </div>
            <div className="option">
                <p className="option_name">Works</p>
            </div>
        </nav>

    )
}
