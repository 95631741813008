import { LoadingProjectCard } from 'Components/UI/LoadingAnimation'
import { ProjectCard } from 'Components/UI/ProjectCard/ProjectCard'
import { Slide, Slideshow } from 'Components/UI/SlideShow/SlideShow'
import { useFetch } from 'CustomHooks/useFetch'
import { useState } from 'react'

export const Works = () => {

    const { data, loading } = useFetch(`${process.env.REACT_APP_URL_API}/get_projects`)
    const [showMore, setShowMore] = useState(false);
    return (
        <section className='section_works'>
            <div className="center_works">
                <div className="header_works">
                    <p className='title_works'>My projects</p>
                </div>
                <div className="projects">
                    {
                        loading ?
                            <>
                                <LoadingProjectCard />
                                <LoadingProjectCard />
                                <LoadingProjectCard />
                            </>
                            :
                            data?.map((item, index) => (

                                !showMore ?
                                    index < 3 && (
                                        <ProjectCard key={index} information={item} />
                                    )
                                    :
                                    <>
                                        <ProjectCard key={index} information={item} />
                                    </>

                            ))
                    }

                    {/* <div className="slide_show">
                        <Slideshow controles={true} autoplay={true}>
                            {
                                loading ?
                                    <p>Loading...</p>
                                    :
                                    data?.map((item, index) => (
                                        <>
                                            <Slide>
                                                <ProjectCard key={index} information={item} />
                                            </Slide>
                                        </>
                                    ))
                            }
                        </Slideshow>
                    </div> */}
                </div>
                <div className="options">
                    {
                        !showMore &&
                        <p className='show_more' onClick={() => setShowMore(true)}>Show more</p>

                    }
                </div>
            </div>


        </section>
    )
}
