import React from 'react'
import { ReactComponent as Github } from 'Assets/Icons/Github.svg'
import { IconsAnimation } from '../IconsAnimation/IconsAnimation'

export const Banner = () => {
    return (
        <section className='section_banner'>
            <div className="center_banner">
                <div className="presentation">
                    <div className="title_name">
                        <p className='im'>I'm</p>
                        <p className='my_name'>Cristian Velasquez</p>
                    </div>
                    <div className="social_networks">
                        <a href="https://github.com/Camilo3312" target="_blank"><Github className='icon_github' /></a>
                    </div>
                </div>
                <div className="about">
                    <div>
                        <p className="subtitle">- Introduction</p>
                        <p className="title">Software developper</p>
                    </div>
                    <p className="information">
                        Junior programmer, willing to change in software today.
                    </p>
                </div>
            </div>
            {/* <IconsAnimation/> */}
        </section>
    )
}
